<!--=========================================================================================
  File Name: VideoPlayer.vue
  Description: Video Player Component
==========================================================================================-->
<template>
  <div class="video-player">
    <div class="control_header">
      <b-row class="control_bg_header">
        <b-col cols="4" class="mod-mw-20">
          <b-row>
            <img
              src="/assets/images/gameroom/back-white-arrow.png"
              class="top-icn-back-gm left"
              @click="redirectToLobby"
              style="cursor: pointer; margin-top: 2vw"
            />
          </b-row>
        </b-col>
      </b-row>
    </div>
    <!-- Betting Timer -->
    <timer
      @playAudio="playAudio"
      :initBetFromBetArea="initBetting"
    />
    <!-- Zoom Button -->
    <span
      :class="['btn-refresh', { loading: this.refreshLoading }]"
      @click="videoRefresh()"
    ></span>
    <!-- Zoom Button -->
    <span class="btn-zoom" @click="videoZoom()"></span>
    <!-- Iframe Source -->
    <iframe
      frameborder="0"
      scrolling="no"
      :src="vidurl"
      :key="vidurl"
      class="video-frame"
      :class="{ 'video-zoom': isZoomed }"
    ></iframe>
    <!-- Roomorder Info -->
    <div class="control_bg_div rm-padding">
      <b-row>
        <div class="data_ma" style="width:100%">
          <marquee>{{ videoText }}</marquee>
        </div>
      </b-row>
      <b-row>
        <animated-number
          :showDiffBalance="showDiffBalance"
          :winLose="winLose"
          :incremental="balamount"
        />
      </b-row>
      <b-row class="control_bg" style="width: 100%">
        <b-col cols="4">
          <b-row>
            <span class="time-label-m">{{ $t("game.liveBaccarat") }}</span>
          </b-row>
          <b-row>
            <span class="time-content-m">{{ timestamp }}</span>
          </b-row>
        </b-col>
        <b-col cols="4">
          <b-row>
            <span class="v-room-order">{{ currentRoom.roomno }}</span>
          </b-row>
          <b-row>
            <span class="v-current-round">{{
              currentRoom.roomorder.slice(currentRoom.roomorder.length - 5) +
                $t("game.round")
            }}</span>
          </b-row>
        </b-col>
        <b-col cols="4" style="text-align:center;">
          <button
            type="button"
            class="btn btn-warning btn-move"
            style="
              background-color: #ffc107;
              height: 1.9em;
              padding-top: 0.11em;
            "
            @click="sidebar()"
          >
            {{ $t("game.changeTable") }}
          </button>
        </b-col>
      </b-row>
      <move-lobby :isPanelOpen="isPanelOpen" @sidebar="sidebar"></move-lobby>
    </div>
  </div>
</template>

<script>
import AnimatedNumber from "@/components/common/AnimatedNumber";
import Timer from "@/components/common/Timer";
import MoveLobby from "@/components/baccarat/game/mobile/MoveLobby";

export default {
  name: "VideoPlayer",
  components: {
    Timer,
    AnimatedNumber,
    MoveLobby,
  },
  computed: {
    prevBettings() {
      return this.$store.state.betModule.prevbettings;
    },
    member() {
      return this.$store.state.gameModule.member;
    },
    currentRoom() {
      return this.$store.state.gameModule.currentRoom;
    },
    vidurl() {
      if (this.refreshLoading) {
        return "";
      } else {
        return this.$store.state.gameModule.video;
      }
    },
    videoText() {
      let settingText = "";
      if (this.$i18n.locale == "en-US") {
        settingText = this.$store.state.gameModule.settings.videoTextEn;
      } else if (this.$i18n.locale == "ch-CH") {
        settingText = this.$store.state.gameModule.settings.videoTextCn;
      } else {
        settingText = this.$store.state.gameModule.settings.videoTextKr;
      }
      if (settingText != "") {
        return settingText;
      } else {
        return this.$t("game.video.marqueeText");
      }
    },
  },
  props: {
    initBetting: {
      required: true,
      type: Boolean,
    },
  },
  mounted() {
    this.$store.commit("gameModule/SET_PREV_BALANCE", this.member.balance);
  },
  data() {
    return {
      isPanelOpen: false,
      timestamp: "",
      isZoomed: false,
      refreshLoading: false,
      currentBalance: 0,
      showDiffBalance: false,
      balamount: 0,
      winLose: false,
    };
  },
  watch: {
    isPanelOpen: function(val) {
      this.isPanelOpen = val;
    },
    currentRoom: {
      handler() {
        this.balamount = 0;
          if (
            this.$store.state.gameModule.currentRoom.state == 1 &&
            this.$store.state.gameModule.currentRoom.result == null
          ) {
            setTimeout(() => {
              this.$store.dispatch('betModule/refreshBalance');
              let balDiff =  parseInt(this.member.balance) - parseInt(this.member.prevBalance);

              if (parseInt(balDiff) > 0) {
                // console.log("WIN")
                this.winLose = true;
              } else if (parseInt(balDiff) < 0) {
                // console.log("LOSE")
                this.winLose = false;
              }

              if (parseInt(balDiff) < 0) {
                balDiff = balDiff * -1;
              }

              if (parseInt(balDiff) > -1) {
                if (!this.initBetting && this.hasPrevBet) {
                  this.showDiffBalance = true;
                }
                this.balamount = balDiff;
              }

              if (this.showDiffBalance) {
                setTimeout(() => {
                   this.showDiffBalance = false;
                  // this.win = false;
                }, 3000);

                this.$store.commit(
                  "gameModule/SET_PREV_BALANCE",
                  this.member.balance
                );
              }
            }, 1500);
          }
      },
      deep: true,
    },
  },
  methods: {
    sidebar() {
      this.isPanelOpen = !this.isPanelOpen;
    },
    redirectToLobby() {
      this.$router.push(`/m/lobby`);
    },
    getNow() {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + " " + time;
      this.timestamp = dateTime;
    },
    videoZoom() {
      if (this.isZoomed == false) {
        this.isZoomed = true;
      } else {
        this.isZoomed = false;
      }
    },
    videoRefresh() {
      this.$socket.client.emit("video_refresh", {
        roomno: this.currentRoom.roomno,
        mobile: true,
      });

      if (!this.refreshLoading) {
        this.refreshLoading = true;
        setTimeout(() => {
          this.refreshLoading = false;
        }, 1000);
      }
    },
    playAudio(id) {
      this.$emit("playAudio", id);
    },
  },
  created() {
    setInterval(this.getNow, 1000);
  },
};
</script>

<style>
.video-player {
  overflow: hidden;
}
.time-label {
  padding-left: 35px;
}
.time-content {
  padding-left: 5px;
}

.control_bg_div {
  position: absolute;
  bottom: 0;
  width: 99%;
}

.control_bg_header {
  background-color: transparent;
}

.control_header {
  position: absolute;
  top: 0;
  width: 99%;
}

.data_marque_div {
  justify-content: center;
  align-items: center;
  padding-top: 0px;
}

.data_ma {
  width: 100%;
}

.btn-zoom {
  cursor: pointer;
  position: absolute;
  bottom: 6vh;
  right: 2vh;
  display: inline-block;
  height: 1vw;
  width: 1vw;
  background: url(/assets/images/game/button/zoom-plus.png) no-repeat;
  background-size: contain;
}

.btn-zoom:hover {
  opacity: 0.7;
}

.btn-refresh {
  cursor: pointer;
  position: absolute;
  bottom: 6vh;
  right: 6vh;
  display: inline-block;
  height: 1vw;
  width: 1vw;
  background: url(/assets/images/game/button/refresh.png) no-repeat;
  background-size: contain;
}

.btn-refresh.loading {
  cursor: progress;
  -webkit-animation: spin 3s linear infinite;
  -moz-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}

.btn-refresh:hover {
  opacity: 0.7;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.video-frame {
  margin-top: 0px;
  height: 60.5vh;
  width: 100%;
  background-color: #000;
  background-image: url("/assets/images/game/history/loading.gif");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 3vw 3vw;
}
.video-zoom {
  width: 300%;
  margin-left: -100%;
}
</style>
