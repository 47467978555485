<!--=========================================================================================
  File Name: BettingResults.vue
  Description: Game Result BettingResults
==========================================================================================-->
<style scoped>
.canvasBox {
  width: 100%;
  position: absolute;
  top: -20vh;
  left: 0;
/*  overflow: hidden;*/
}
#canvas {
  width: auto !important;
}
</style>
<template>
<div class="bet-res" v-if="result != undefined && result != ''">
    <transition name="fade" v-on:enter="enter">
        <b-row v-if="show" class="result-row">
            <div id="app" style="position: absolute;">
                <Fireworks v-if="showFireworks != 0" :boxHeight="'100%'" :boxWidth="'100%'"/>
            </div>
            <b-col class="img-top">
                <img v-bind:src="imgPath">
            </b-col>
        </b-row>
    </transition>
</div>
</template>

<script>
import Fireworks from '@/components/baccarat/game/mobile/Fireworks'
export default {
    name: 'BettingResults',
    components: {
        Fireworks
    },
    computed: {
        showFireworks() {
            return this.$store.state.gameModule.settings.showFireworks;
        },
    },
    data() {
        return {
            imgPath: "",
            show: false
        }
    },
    props: {
        result: {
            required: false,
            type: String
        },
        gameType: {required: true, type: String}
    },
    watch: {
        result: {
            handler(newVal) {
                this.fade();
                if(this.gameType == 'DT') { this.imgPath = "/assets/images/gameroom/results/" + newVal + "_winDT.png" }
                else  { this.imgPath  = "/assets/images/gameroom/results/" + newVal + "_win.png" }
                // this.imgPath = "/assets/images/gameroom/results/" + newVal + "_win.png"
                this.enter();

            },
            deep: true
        }
    },
    methods: {
        fade: function () {
            //this.show = !this.show
            this.show = true;
        },
        enter: function () {
            setTimeout(function () {
                this.show = false;
            }, 4000);
        }

    }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
/* .bet-res {
    position: absolute;
    z-index: 15;
    right: 0;
    height: 60vh;
    width: 100%;
    text-align: center;
} */

.bet-res {
    position: absolute;
    z-index: 15;
    right: 0;
    height: 34vh !important;
    width: 100%;
    text-align: center;
}
.bet-res img {
    max-width: 100%;
}

.result-row {
    margin: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s
}

.fade-enter,
.fade-leave-to {
    opacity: 0
}

@media only screen and (min-width: 720px) and (orientation: landscape) {
    .bet-res {
        height: 77vh !important;
    }

    .img-top {
        top: 50px;
    }
}


@media only screen and (min-width: 980px) and (orientation: landscape) {
    .bet-res {
        height: 79vh !important;
    }

    .img-top {
        top: 140px;
    }
}
</style>
