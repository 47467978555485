<!--=========================================================================================
  File Name: ResulBigtRoad.vue
  Description: Result Big Road Component
==========================================================================================-->
<template>
<div class="content grid-lines">
    <div class="carfbig-road" ref="shoe-tbl">
        <img @click="scrollTarget('left')" v-if="showLeftArrow" src="http://dev99dev.com/assets/images/btn_hisprev.png" alt="Prev Button" class="big-road-prev">
        <div class="rs-ctnr-m d-flex flex-row grid-y grid-y-repeat-big-m" v-bind:style="{width: shoeW }">
            <div class="flex-column" v-for="(shoeM,x) in shoeMatrix" :key="x" v-bind:style="{'width':widthShoe + 'vh'}" >
                <div v-for="(shoe, i) in shoeM" v-bind:key="shoe.index + '-' + i"  v-bind:style="{'height':heightShoe + 'vh', 'width':widthShoe + 'vh'}" class="big-road-shoe tie-cnt-m">
                    <div  class="shoe-item shoe-text"  v-bind:style="{'height':heightShoe + 'vh', 'width':widthShoe  + 'vh'}"
                          v-bind:class="[{ animate_latest: x == shoeMatrix.length - 1 && (shoe.shoe !='' && i == $getLasttIndex(shoeMatrix, x) )}, shoe.shoe + '-big-road'] ">
                            <span class='tie-cnt-txt' v-if="shoe.count > 0">{{ shoe.count }}</span>
                    </div>
                </div>
            </div>


        </div>
        <img @click="scrollTarget('right')" v-if="showRightArrow" src="http://dev99dev.com/assets/images/btn_hisnext.png" alt="Next Button" class="big-road-next">
    </div>
</div>
</template>
<script>
export default {
    name: 'ResultBigRoad',
    components: {},
    data() {
        return {
            showRightArrow: false,
            showLeftArrow: false,
            width: 0,
            window: {
                width: 0,
                height: 0
            },
            scrollW: 0,
            shoeW: ''
        }
    },
    props: {
        shoeResults: {
            required: true,
            type: String
        },
        heightShoe: {
            required: true,
            type: Number
        },
        widthShoe: {
            required: true,
            type: Number
        },

    },
    // created() {
    //     window.addEventListener('resize', this.handleResize);
    //     this.handleResize();
    // },
    computed: {
        shoeMatrix() {
            let matrix = [],
                prevShoe = '',
                columnIdx = -1,
                rowIdx = 1,
                sameShoeCnt = 0,
                tempColumnIdx = -1,
                tmpInd = 5,
                tieCnt = 0,
                idx = 0;
            const tieShoe = ['i', 'j', 'k', 'l']
            this.shoeResults.split('').forEach((shoe) => {
                if (!(tieShoe.indexOf(shoe) !== -1)) { // If shoe is not tie
                    if (!((this.$prevShoeArr(prevShoe)).indexOf(shoe) !== -1)) { // create new column
                        if (matrix[columnIdx + 1]) { // check if there's an over shoe, overwrite 1st column
                            matrix[columnIdx + 1][0] = {
                                shoe,
                                count: 0
                            }
                        } else {
                            matrix.push([{
                                shoe,
                                count: 0
                            }, {
                                shoe: '',
                                count: 0
                            }, {
                                shoe: '',
                                count: 0
                            }, {
                                shoe: '',
                                count: 0
                            }, {
                                shoe: '',
                                count: 0
                            }, {
                                shoe: '',
                                count: 0
                            }])
                        }
                        rowIdx = 1; // reset row index to 1 (next vertical shoe)
                        sameShoeCnt = 0 // reset same shoe count
                        columnIdx++; // increment column index
                        tempColumnIdx = columnIdx;
                        tieCnt = 0;
                    } else { // normal add
                        if (sameShoeCnt > rowIdx) {
                            matrix.push([{
                                shoe: '',
                                count: 0
                            }, {
                                shoe: '',
                                count: 0
                            }, {
                                shoe: '',
                                count: 0
                            }, {
                                shoe: '',
                                count: 0
                            }, {
                                shoe: '',
                                count: 0
                            }, {
                                shoe: '',
                                count: 0
                            }])
                            tempColumnIdx++
                            matrix[tempColumnIdx][rowIdx] = {
                                shoe,
                                count: 0
                            }
                            if (tmpInd < 5) { //
                                if (matrix[tempColumnIdx][rowIdx + 1].shoe == '') {
                                    tempColumnIdx--
                                    rowIdx++
                                    tmpInd = rowIdx
                                }
                            }
                        } else {
                            if (matrix[tempColumnIdx] && matrix[tempColumnIdx][rowIdx].shoe == '') {
                                matrix[tempColumnIdx][rowIdx] = {
                                    shoe,
                                    count: 0
                                }
                            } else {
                                tmpInd = rowIdx - 1
                                // check if the matrix has enough length to handle dragon tail
                                if (matrix.length < tempColumnIdx) {
                                    matrix.push([{
                                        shoe: '',
                                        count: 0
                                    }, {
                                        shoe: '',
                                        count: 0
                                    }, {
                                        shoe: '',
                                        count: 0
                                    }, {
                                        shoe: '',
                                        count: 0
                                    }, {
                                        shoe: '',
                                        count: 0
                                    }, {
                                        shoe: '',
                                        count: 0
                                    }])
                                }

                                matrix.push([{
                                    shoe: '',
                                    count: 0
                                }, {
                                    shoe: '',
                                    count: 0
                                }, {
                                    shoe: '',
                                    count: 0
                                }, {
                                    shoe: '',
                                    count: 0
                                }, {
                                    shoe: '',
                                    count: 0
                                }, {
                                    shoe: '',
                                    count: 0
                                }])
                                tempColumnIdx++
                                rowIdx--
                                matrix[tempColumnIdx][rowIdx] = {
                                    shoe,
                                    count: 0
                                }

                                // Always check the bottom index
                                if (tmpInd < 5) {
                                    if (matrix[tempColumnIdx][rowIdx + 1].shoe == '') {
                                        tempColumnIdx--
                                        rowIdx++
                                        tmpInd = rowIdx
                                    }
                                }
                            }
                        }
                        if (rowIdx < tmpInd)
                            rowIdx++; // increment row index
                    }

                    if (columnIdx >= 0 && rowIdx > 0) {
                        idx++;
                        matrix[columnIdx][rowIdx - 1].index = idx;
                    }

                    prevShoe = shoe // set current shoe as prev
                    sameShoeCnt++; // same Shoe
                    tieCnt = 0 // reset tie counting.
                } else {
                    tieCnt++;
                    if (tempColumnIdx > 0) { // if Tie is the first result
                        if (sameShoeCnt > rowIdx) {
                            if (rowIdx > -1 && tempColumnIdx > -1) {
                                matrix[tempColumnIdx][rowIdx] = {
                                    shoe: prevShoe,
                                    count: tieCnt
                                }
                            }
                        } else {
                            matrix[tempColumnIdx][rowIdx - 1] = {
                                shoe: prevShoe,
                                count: tieCnt
                            }
                        }
                    }

                }
            })
            return matrix
        }
    },


}
</script>

<style>
.tie-cnt-txt {
  font-size: 1.2em;
  color: green;
  line-height: 0.7em;
  font-weight: 900;
}
.big-road-tbl td {
    border: 1px solid #D8D8D8;
    border-top: none !important;
}

.big-road-tbl {
    display: block;
    overflow: hidden;
}

.big-road-next {
    z-index: 11;
    position: absolute;
    height: 8.5vh;
    top: 55px;
    right: 15px;

}

.big-road-prev {
    z-index: 11;
    position: absolute;
    height: 8.5vh;
    top: 55px;
    left: 0;
}

.tie-cnt-m {
  line-height: 0.5 !important;
}

.rs-ctnr-m {
  border-bottom: 1px #e7e7e7 solid;
}

@media (max-width: 767px){
.logo {
    margin-top: 1vw;
    background-size: 38vw;
}
}

</style>
