<template>
    <div class="sidebar" >
        <transition name="slide">
            <div v-if="isPanelOpen" class="sidebar-panel"  ref='sidebar' style=" transition: all .5s ease;">
                <b-container class="rm-padding">
                  <b-row>
                      <b-col cols="12">
                          <p style="text-align:center">
                              <b-button class='btn-warning' style="background-color:#ffc107;margin-top:2vh" @click="closePanel()">{{ $t('uiLabels.close') }}</b-button>
                          </p>
                      </b-col>
                  </b-row>
                    <b-row class="rm-padding">
                        <b-col cols="12" class="rm-padding">
                            <div class="room-list rm-padding" style="overflow:hidden;">
                                <div v-for="(room,idx) in gameRooms" :key="idx">
                                    <span v-if="!room.isLocked">
                                        <move-lobby-shoe :room="room"></move-lobby-shoe>
                                    </span>
                                    <span v-if="room.isLocked">
                                        <move-lobby-closed :room="room"></move-lobby-closed>
                                    </span>
                                </div>
                            </div>
                        </b-col>
                    </b-row>

                </b-container>
            </div>
        </transition>
    </div>
</template>
<script>
    import MoveLobbyShoe from '@/components/baccarat/roads/MoveLobbyShoe'
    import MoveLobbyClosed from '@/components/baccarat/roads/MoveLobbyClosed'

    export default {
        components: {
            MoveLobbyShoe,
            MoveLobbyClosed
        },
        data() {
          return {
            touch: {
              startX: 0,
              endX: 0
            }
          }
        },
        methods: {
            // closeSidebarPanel: mutations.toggleNav
            closePanel() {
                this.$emit('sidebar');
            },
            touchstart(event) {
              this.touch.startX = event.touches[0].clientX;
              this.touch.endX = 0;
            },
            touchmove(event) {
              this.touch.endX = event.touches[0].clientX;
            },
            touchend() {
              if(!this.touch.endX || Math.abs(this.touch.endX - this.touch.startX) < 20)
                return;
              if(!(this.touch.endX < this.touch.startX))
              this.$emit('sidebar', true);
            }
        },
        watch : {
               isPanelOpen:function(val) {
                  this.isPanelOpen = val;
               },
           },
        props: {
            isPanelOpen: {
                required: true,
                type: Boolean
            }
        },
        computed: {
            gameRooms() {
                let rooms = []
                switch('') {
                    case '':  rooms = this.$store.state.gameModule.game.rooms; break;
                    case 'BAC': rooms = this.$store.state.gameModule.game.rooms.filter(r => { return r.type == 'BAC' }); break;
                    case 'SBAC': rooms = this.$store.state.gameModule.game.rooms.filter(r => { return r.type == 'SBAC' }); break;
                    case 'DT': rooms = this.$store.state.gameModule.game.rooms.filter(r => { return r.type == 'DT' }); break;
                }
                return rooms
            },
        },
        mounted() {
          this.$el.addEventListener('touchstart', event => this.touchstart(event));
          this.$el.addEventListener('touchmove', event => this.touchmove(event));
          this.$el.addEventListener('touchend', () => this.touchend());
}
    }
</script>
<style scoped>

    .sd-container{
      padding-top: 6px;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      /* background-color: #161616; */
      background: rgba(0, 0, 0, 0.4);
      border: 1px solid rgba(190, 174, 147, 0.6);
      float: left;
      width: 44vw;
      margin:6px;
    }
    .lobby-close-button{
        height: 7vh;
    }
    .slide-enter-active,
    .slide-leave-active
    {
        transition: transform 0.2s ease;
    }

    .slide-enter,
    .slide-leave-to {
        transform: translateX(100%);
        transition: all 100ms ease-in 0s
    }

    /* Enter and leave animations can use different */
    /* durations and timing functions.              */
    .slide-fade-enter-active {
      transition: all .3s ease;
    }
    .slide-fade-leave-active {
      transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to
    {
      transform: translateX(10px);
      opacity: 0;
    }

    .sidebar-panel {
      overflow-y: auto;
      background-color: #130f40;
      background: rgba(0, 0, 0, 0.7);
      position: fixed;
      right: 0;
      top: 0;
      height: 100vh;
      z-index: 999;
      padding: 0rem 0rem 0rem 0rem;
      width: 96vw;
      font-size: 16px;
    }

    .openSidebar {
        right: 491px !important;
        position: absolute !important;
        background: rgba(0, 0, 0, 0.7)  !important;

    }

    .sidebar-panel::-webkit-scrollbar-track
    {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
        background-color: #F5F5F5;

    }

    .sidebar-panel::-webkit-scrollbar
    {
        width: 5px;
        background-color: #F5F5F5;
    }

    .sidebar-panel::-webkit-scrollbar-thumb
    {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #202020;
    }

</style>
