<!--=========================================================================================
  File Name: BettingArea.vue
  Description: Betting Area Component
==========================================================================================-->
<template>
<div class="betting-container" :class="{ 'betting-disabled': isBetClosed || initBetting }">

    <b-row>
        <!-- Video Player -->
        <b-col class="rm-side-padding col-ipad-pro col-12 col-lg-6 col-md-6 col-sm-6">
            <video-player @playAudio="playAudio" :initBetting ="initBetting"/>
        </b-col>

        <!-- Betting Area -->
        <b-col class="pc-bet-area rm-side-padding col-ipad-pro col-12 col-lg-6 col-md-6 col-sm-6">
            <!-- Game Room Header -->
            <!-- <game-room-header /> -->
            <!-- End Game Room Header -->

            <div :class="['row',{'baccarat': gameType != 'DT', 'baccarat-dt': gameType == 'DT', '': isBetClosed || initBetting }]" class="bet-main-content">
                <div v-if="isBetClosed || initBetting" :class="['closed-bet-area']">
                    <span v-if="!currentRoom.result" :class="['betting-closed-message']">{{ $t('game.bettingMessage.closed') }}</span>
                </div>
                <div v-if="isNotificationOn" :class="['notification-area']">
                    <div :class="['notification-container']"><span :class="['notification-message']">{{ $t('game.bettingNotification.' + betResponse) }}</span></div>
                </div>
                <div v-if="showError" :class="['notification-area']">
                    <div :class="['notification-container']"><span :class="['notification-message']">{{ errorMessage }}</span></div>
                </div>

                <betting-results :result='currentRoom.result' :gameType="gameType"></betting-results>
                <!-- Side Bettings -->
                <b-row class="bet-area bet-area-top" v-if = "gameType != 'DT'">
                    <!-- Player Pair -->
                    <b-col class="player-pair-bet-area" @click="placeBet(game.chipSelected,'player_pair')">
                        <span class="bet-area-label-top">{{ $t('game.playerPair') }}</span>
                        <span class="bet-area-label-bot">{{ $t('game.playerPairRate') }}</span>
                        <span class="bet-area-val">{{ totalPlayerPairBet | currency }}</span>
                    </b-col>
                    <!-- Tie -->
                    <b-col class="tie-bet-area" @click="placeBet(game.chipSelected,'tie')">
                        <span class="bet-area-label-top">{{ $t('game.tie') }}</span>
                        <span class="bet-area-label-bot tie-bot-label">{{ $t('game.tieRate') }}</span>
                        <span class="bet-area-val tie-bot-val">{{ totalTieBet | currency }}</span>
                    </b-col>
                    <!-- Banker Pair -->
                    <b-col class="banker-pair-bet-area" @click="placeBet(game.chipSelected,'banker_pair')">
                        <span class="bet-area-label-top">{{ $t('game.bankerPair') }}</span>
                        <span class="bet-area-label-bot">{{ $t('game.bankerPairRate') }}</span>
                        <span class="bet-area-val">{{ totalBankerPairBet | currency }}</span>
                    </b-col>
                </b-row>
                <!-- Main Bettings -->
                <b-row align-v="stretch" class="bet-area bet-area-bottom" v-if = "gameType != 'DT'">
                    <!-- Player -->
                    <b-col align-v="stretch" class="player-bet-area" @click="placeBet(game.chipSelected,'player')">
                        <span class="bet-area-label-top">{{ $t('game.player') }}</span>
                        <span class="bet-area-label-bot">{{ $t('game.playerRate') }}</span>
                        <span class="bet-area-val">{{ totalPlayerBet | currency }}</span>
                    </b-col>
                    <!-- Banker -->
                    <b-col align-v="stretch" class="banker-bet-area" @click="placeBet(game.chipSelected,'banker')">
                        <span class="bet-area-label-top">{{ $t('game.banker') }}</span>
                        <span class="bet-area-label-bot">{{ $t('game.bankerRate') }}</span>
                        <span class="bet-area-val">{{ totalBankerBet | currency }}</span>
                    </b-col>
                </b-row>

                <b-row align-v="stretch" class="bet-area bet-area-bottom-dt" v-if = "gameType == 'DT'">
                    <!-- Player -->
                    <b-col align-v="stretch" class="banker-bet-area" @click="placeBet(game.chipSelected,'banker')">
                        <span class="bet-area-label-top">{{ $t('game.dragon') }}</span>
                        <span class="bet-area-label-bot">{{ $t('game.dragonRate') }}</span>
                        <span class="bet-area-val">{{ totalBankerBet | currency }}</span>
                    </b-col>
                    <!-- Tie -->
                    <b-col class="tie-bet-area" @click="placeBet(game.chipSelected,'tie')">
                        <span class="bet-area-label-top">{{ $t('game.tie') }}</span>
                        <span class="bet-area-label-bot tie-bot-label">{{ $t('game.tieRateDt') }}</span>
                        <span class="bet-area-val tie-bot-val">{{ totalTieBet | currency }}</span>
                    </b-col>
                    <!-- Banker -->
                    <b-col align-v="stretch" class="player-bet-area" @click="placeBet(game.chipSelected,'player')">
                         <span class="bet-area-label-top">{{ $t('game.tiger') }}</span>
                        <span class="bet-area-label-bot">{{ $t('game.tigerRate') }}</span>
                        <span class="bet-area-val">{{ totalPlayerBet | currency }}</span>
                    </b-col>
                </b-row>

                 <!-- Chips and Submit -->
                <b-row class='betting-action-area'>
                    <b-col cols = '12'>
                        <span class="chip" v-for="(chip, idx) in chips.filter(s => s.chipamt != 0 || null)" :key="idx" :class="{ 'chip-selected': game.chipSelected == chip.chipamt, 'chip-disabled': isBetClosed || initBetting ,
                        'loader': game.chipSelected == chip.chipamt}"
                            @click="selectChipValue(chip.chipamt)"><div>{{ showChipDispay(chip) }}</div></span>
                    </b-col>
                    <b-col style="margin-left:15px;" cols = '12'>
                            <!-- Betting Action -->
                            <betting-action :bet="bet" :successfulBet="successfulBet" @initBet="initBet" @resetGame="resetGame" @setErrorMessage="setErrorMessage" :prevBetTime="this.prevBetTime" />
                    </b-col>

                </b-row>
            </div>

            <b-row class="bar_row3">
                <div class="msg rowmsg">{{ gameStateLabel }}</div>
            </b-row>
            <b-row class="bar_row4">
                <b-col cols='6' class="i_room_amt" style="padding-top: 2px;">
                    {{ $t('game.balance')}}:
                    <img class="reloadb" @ src="/assets/images/gameroom/reloadb.png"  @click="refreshBalance()">
                    <span class="game_account">{{ member.balance | currency }}</span>
                </b-col>
                <b-col cols='6' class="i_bet">
                    {{ $t('game.totalBet') }}: <span id="id_totalbet">{{ successTotalBet | currency }}</span>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
    <move-lobby @initBettingSetFalse="initBettingSetFalse" :isPanelOpen='isPanelOpen' :sidebar="sidebar"></move-lobby>

</div>
</template>

<script>

import BettingResults from '@/components/baccarat/game/mobile/BettingResults'
import VideoPlayer from '@/components/baccarat/game/mobile/VideoPlayer'
// import GameRoomHeader from '@/components/baccarat/game/mobile/GameRoomHeader'
import BettingAction from '@/components/baccarat/game/mobile/BettingAction'
import MoveLobby from '@/components/baccarat/game/mobile/MoveLobby'

export default {
    name: 'BettingArea',
    components: {
        BettingResults, BettingAction, MoveLobby, VideoPlayer
    },
    props: {
        gameType: {required: true, type: String},
    },
    data() {
        return {
            isPanelOpen:false,
            game: {
				chipSelected: 0
            },
            bet: [],
            successfulBet: {
                player: 0,
                player_pair: 0,
                banker: 0,
                banker_pair: 0,
                tie: 0,
            },
            isBetReset: false,
            isBetCleared: false,
            errorMessage: '',
            showError: false,
            initBetting: true,
            noBetCounter: 0,
            counterAdded: false,
            currentRoomOrder: '',
            prevBetTime: 0,
        }
    },
    computed: {
		limit() {
			return this.$store.getters['gameModule/getCurrentLevelLimit']
		},
        currentRoom() {
            return this.$store.state.gameModule.currentRoom;
        },
        isBetClosed() {
            return this.$store.state.gameModule.currentRoom.state != 1;
        },
        member() {
            return this.$store.state.gameModule.member;
        },
        chips() {
			return this.$store.state.gameModule.betChips;
        },
        betResponse() {
			return this.$store.state.betModule.notifications.message;
        },
        isNotificationOn() {
			return this.$store.state.betModule.notifications.show;
        },
        maxAllowedNoBet() {
            return this.$store.state.gameModule.settings.maxRoundRoomKick;
        },
        showFireworks() {
            return this.$store.state.gameModule.settings.showFireworks;
        },
        gameStateLabel() {

            let label = null

            switch(this.currentRoom.state) {
                case 1: {
                    label = this.$t('gameState.startBetting');
                    break;
                }
                case 2: {
                    label = this.$t('gameState.stopBetting');
                    break;
                }
                case 3: {

                     if(this.currentRoom.result == 'a' || this.currentRoom.result == 'b' || this.currentRoom.result == 'c' || this.currentRoom.result == 'a'){

                        label = this.gameType === 'DT' ? this.$t('gameState.dragonWin') : this.$t('gameState.bankerWin');
                        this.playAudio('banker-win');
                    }
                    else if(this.currentRoom.result == 'e' || this.currentRoom.result == 'f' || this.currentRoom.result == 'g' || this.currentRoom.result == 'h') {

                        label = this.gameType === 'DT' ? this.$t('gameState.tigerWin') : this.$t('gameState.playerWin');
                        this.playAudio('player-win');
                    }
                    else if(this.currentRoom.result == 'i' || this.currentRoom.result == 'j' || this.currentRoom.result == 'k' || this.currentRoom.result == 'l') {
                        label = this.$t('gameState.tieWin');
                        this.playAudio('tie-win');
                    }

                    break;
                }
                case 4: label = this.$t('gameState.shuffling')
            }

            if (this.initBetting) {
                label = this.$t('gameState.nextBetting');
            }

            return label
        },
        completedBet() {
			return this.$store.state.betModule.bettings;
        },
        totalPlayerBet() {
            var filteredArray, returnAmount = 0;
            if (this.bet) {
                filteredArray = this.bet.filter(function(itm){
                    return ['player'].indexOf(itm.betpos) > -1;
                });
                if (filteredArray) {
                    filteredArray.forEach((key, item) => {
                        returnAmount = parseInt(returnAmount) + parseInt(filteredArray[item].betamt);
                    });
                }
            }
            if (this.completedBet) {
                filteredArray = this.completedBet.filter(function(itm){
                    return ['player'].indexOf(itm.betpos) > -1;
                });
                if (filteredArray) {
                    filteredArray.forEach((key, item) => {
                        this.successfulBet.player = filteredArray[item].betamt;
                        returnAmount = parseInt(returnAmount) + parseInt(filteredArray[item].betamt);
                    });
                }
            }

            return returnAmount;
        },
        totalPlayerPairBet() {
            var filteredArray, returnAmount = 0;
            if (this.bet) {
                filteredArray = this.bet.filter(function(itm){
                    return ['player_pair'].indexOf(itm.betpos) > -1;
                });
                if (filteredArray) {
                    filteredArray.forEach((key, item) => {
                        returnAmount = parseInt(returnAmount) + parseInt(filteredArray[item].betamt);
                    });
                }
            }
            if (this.completedBet) {
                filteredArray = this.completedBet.filter(function(itm){
                    return ['player_pair'].indexOf(itm.betpos) > -1;
                });
                if (filteredArray) {
                    filteredArray.forEach((key, item) => {
                        this.successfulBet.player_pair = filteredArray[item].betamt;
                        returnAmount = parseInt(returnAmount) + parseInt(filteredArray[item].betamt);
                    });
                }
            }
            return returnAmount;
        },
        totalBankerBet() {
            var filteredArray, returnAmount = 0;
            if (this.bet) {
                filteredArray = this.bet.filter(function(itm){
                    return ['banker'].indexOf(itm.betpos) > -1;
                });
                if (filteredArray) {
                    filteredArray.forEach((key, item) => {
                        returnAmount = parseInt(returnAmount) + parseInt(filteredArray[item].betamt);
                    });
                }
            }
            if (this.completedBet) {
                filteredArray = this.completedBet.filter(function(itm){
                    return ['banker'].indexOf(itm.betpos) > -1;
                });
                if (filteredArray) {
                    filteredArray.forEach((key, item) => {
                        this.successfulBet.banker = filteredArray[item].betamt;
                        returnAmount = parseInt(returnAmount) + parseInt(filteredArray[item].betamt);
                    });
                }
            }
            return returnAmount;
        },
        totalBankerPairBet() {
            var filteredArray, returnAmount = 0;
            if (this.bet) {
                filteredArray = this.bet.filter(function(itm){
                    return ['banker_pair'].indexOf(itm.betpos) > -1;
                });
                if (filteredArray) {
                    filteredArray.forEach((key, item) => {
                        returnAmount = parseInt(returnAmount) + parseInt(filteredArray[item].betamt);
                    });
                }
            }
            if (this.completedBet) {
                filteredArray = this.completedBet.filter(function(itm){
                    return ['banker_pair'].indexOf(itm.betpos) > -1;
                });
                if (filteredArray) {
                    filteredArray.forEach((key, item) => {
                        this.successfulBet.banker_pair = filteredArray[item].betamt;
                        returnAmount = parseInt(returnAmount) + parseInt(filteredArray[item].betamt);
                    });
                }
            }
            return returnAmount;
        },
        totalTieBet() {
            var filteredArray, returnAmount = 0;
            if (this.bet) {
                filteredArray = this.bet.filter(function(itm){
                    return ['tie'].indexOf(itm.betpos) > -1;
                });
                if (filteredArray) {
                    filteredArray.forEach((key, item) => {
                        returnAmount = parseInt(returnAmount) + parseInt(filteredArray[item].betamt);
                    });
                }
            }
            if (this.completedBet) {
                filteredArray = this.completedBet.filter(function(itm){
                    return ['tie'].indexOf(itm.betpos) > -1;
                });
                if (filteredArray) {
                    filteredArray.forEach((key, item) => {
                        this.successfulBet.tie = filteredArray[item].betamt;
                        returnAmount = parseInt(returnAmount) + parseInt(filteredArray[item].betamt);
                    });
                }
            }
            return returnAmount;
        },
        totalBetAmt() {
            var returnAmount = 0;
            if (this.bet) {
                this.bet.forEach((key, item) => {
                    returnAmount = parseInt(returnAmount) + parseInt(this.bet[item].betamt);
                });
            }
            return returnAmount;
        },
        successTotalBet() {
            var returnAmount = 0;
            this.completedBet.forEach((key, item) => {
                returnAmount = parseInt(returnAmount) + parseInt(this.completedBet[item].betamt);
            });
            return returnAmount;
        },
        totalShoe() {
            return this.currentRoom.shoe.length || 0
        },
        muteAudio() {
            if (localStorage.getItem('muteAudio') === null) {
                localStorage.setItem('muteAudio', 0);
            }
            return localStorage.getItem('muteAudio');
        },
        allowCrossBets() {
            return this.$store.state.gameModule.settings.crossBets
		}
    },
    methods : {
        sidebar() {
            this.isPanelOpen = !this.isPanelOpen;
        },
        placeBet(amount, target) {

            if (this.isBetClosed) {
				this.setErrorMessage('BET_CLOSED');
				return false;
            }

            if(!this.allowCrossBets) {
                if (target == 'banker' && (this.totalPlayerBet > 0 || this.bet.some(o => o.betpos === 'player'))) {
                    this.setErrorMessage('BANKER_BET_CONFLICT');
                    return false;
                } else if (target == 'player' && (this.totalBankerBet > 0  || this.bet.some(o => o.betpos === 'banker'))) {
                    this.setErrorMessage('PLAYER_BET_CONFLICT');
                    return false;
                }
            }

            if (this.member.balance >= (this.totalBetAmt + amount) ) {

                let betObj = {
                    betpos: target,
                    betamt: amount
                };

                var betAmount;

                // Check if betposition already exists in the array
                const checkIfBetposExist = this.bet.some(o => o.betpos === target)

                // Update Array
                if(checkIfBetposExist) {

                    const idx = this.bet.findIndex(b => b.betpos == target)
                    var newAmount = parseInt(this.bet[idx].betamt) + parseInt(amount);

                    betAmount = this.limitBet(newAmount, this.bet[idx].betpos);

                    this.bet[idx].betamt = betAmount;

                } else {
                    betAmount = this.limitBet(amount, target);
                    betObj.betamt = betAmount;

                    this.bet.push(betObj);
                }

                this.playAudio('chip');

                this.isBetReset = false;
                this.isBetCleared = false;
            } else {
                this.setErrorMessage('INSUFFICIENT_FUNDS');
            }

        },
        limitBet(amount, target) {
            var betMin, betMax,
                totalBet = 0;

            switch(target) {
                case 'player':
                    betMin = this.limit.player_min;
                    betMax = this.limit.player_max;
                    if (this.successfulBet.player) {
                        totalBet = this.successfulBet.player;
                    }
                    break;
                case 'banker':
                    betMin = this.limit.banker_min;
                    betMax = this.limit.banker_max;
                    totalBet = this.successfulBet.banker;
                    if (this.successfulBet.banker) {
                        totalBet = this.successfulBet.banker;
                    }
                    break;
                case 'player_pair':
                    betMin = this.limit.pair_min;
                    betMax = this.limit.pair_max;
                    if (this.successfulBet.player_pair) {
                        totalBet = this.successfulBet.player_pair;
                    }
                    break;
                case 'banker_pair':
                    betMin = this.limit.pair_min;
                    betMax = this.limit.pair_max;
                    if (this.successfulBet.banker_pair) {
                        totalBet = this.successfulBet.banker_pair;
                    }
                    break;
                case 'tie':
                    betMin = this.limit.tie_min;
                    betMax = this.limit.tie_max;
                    totalBet = this.successfulBet.tie;
                    if (this.successfulBet.tie) {
                        totalBet = this.successfulBet.tie;
                    }
                    break;
            }

            if ((parseInt(totalBet) + parseInt(amount)) < parseInt(betMin)) {
                amount = parseInt(betMin) - totalBet;
            } else if ((parseInt(totalBet) + parseInt(amount)) > betMax) {
                amount = parseInt(betMax) - totalBet;
            }
            return parseInt(amount);
        },
        selectChipValue(selectedValue) {
			/*if (this.game.chipSelected != selectedValue) {
				this.game.chipSelected = selectedValue;
			} else {
				this.game.chipSelected = 0;
			}*/
			if (!this.isBetClosed) {
				this.game.chipSelected = selectedValue;
			}
        },
        showChipDispay(chip) {
			if (this.game.chipSelected == 0) {
				this.game.chipSelected = chip.chipamt;
			}
			var chipDisplay = '';
			var res;
			if (chip.currency == 'KRW') {

				if (chip.chipamt >= 10000 ) {
					res = ~~(chip.chipamt / 10000);
					chipDisplay +=  res + this.$t('game.chipCurrency.KRW10000');
				}
				if ((res = chip.chipamt % 10000) > 0 ) {
					chipDisplay +=  ~~(res / 1000) + this.$t('game.chipCurrency.KRW1000');
				}
			} else {
				chipDisplay = ~~(chip.chipamt / 1000)  + this.$t('game.chipCurrency.USD1000');
			}
			return chipDisplay;
        },
        initBet() {
            this.bet = [];
        },
		resetBet() {
            this.bet = [];
            this.successfulBet = {};
			this.isBetReset = true;
		},
		resetGame() {
            this.resetBet();
            this.$store.dispatch('betModule/betClear');
            this.successfulBet.player = 0;
            this.successfulBet.player_pair = 0;
            this.successfulBet.banker = 0;
            this.successfulBet.banker_pair = 0;
            this.successfulBet.tie = 0;
            this.prevBetTime = 0;
        },
        setErrorMessage(errorCode) {
            this.errorMessage = this.$t('game.errorMessage.' + errorCode);
            this.showError = true;
            setTimeout(() => this.showError = false, 1000);
        },
        playAudio(id){
            this.$emit('playAudio', id);
        },
        refreshBalance() {
            this.$store.dispatch('betModule/refreshBalance');
        },
        addBetCounter() {
            this.noBetCounter++;
        },
        initBettingSetFalse() {
            this.initBetting = true;
        },
    },
    watch: {
        isBetClosed: {
            handler(){
                if (this.$store.state.gameModule.currentRoom.state != 1 && this.initBetting) {
                    this.initBetting = false;
                }
                if (this.currentRoomOrder != this.$store.state.gameModule.currentRoom.roomorder && this.isBetReset == false) {
                    this.resetGame();
                }
                this.currentRoomOrder = this.$store.state.gameModule.currentRoom.roomorder;
                if (this.$store.state.gameModule.currentRoom.state == 1) {
                    if (!this.initBetting) {
                        this.addBetCounter();
                        this.counterAdded = true;
                    }
                    if (this.isBetReset == false) {
                        this.resetGame();
                    }
                } else if (this.$store.state.gameModule.currentRoom.state == 2 || this.$store.state.gameModule.currentRoom.state == 2) {
                    this.initBet();
                }
                if (!this.initBetting && this.$store.state.gameModule.currentRoom.state != 1) {
                    this.counterAdded = false;
                }
            },
            deep: true
        },
        betResponse: {
            handler(){
                if (this.betResponse == 'bettingSuccessful') {
                    this.playAudio('betting-successful');
                    this.noBetCounter = 0;
                    const today = new Date();
                    this.prevBetTime = today.getTime();
                }
                if (this.betResponse == 'BET_CANCEL_SUCCESS') {
                    this.resetGame();
                }
            }
        },
        noBetCounter: {
            handler(val){
                if (val > this.maxAllowedNoBet) {
                    this.$store.dispatch('gameModule/forceLogout')
                }
            }
        }
    }
}
</script>

<style scope>

.betting-container {
    width: 100%;
    height: 100%;
}

.bet-area {
    width: 100%;
    height: 50%;
    text-align: center;
    margin: 0;
}

.bet-area .col {
    width: 100%;
    height: 100%;
    /* border: solid 0.1vw #000; */
    cursor: pointer;
}



.betting-closed-message {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2vw;
    height: 100%;
    width: 100%;
    margin-top: -5vw;
}

.betting-disabled .bet-area .col {
    cursor: auto;
}

.notification-container {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2vw;
    height: 100%;
    width: 100%;
    margin-top: -5vw;
}

.notification-message {
    background: rgba(0, 0, 0, 0.5);
    padding: 0.5vw 1.5vw;
    border: 0.1vw solid #fff;
    border-radius: 0.5vw;
}

.player-bet-area, .player-pair-bet-area {
    color: #00f;
}

.bet-area-top {
    height: 25%;
    margin-top: 1vh;
}

.bet-area-bottom {
   height: 27%;
}
.banker-bet-area, .banker-pair-bet-area {
    color: #f00;
}


.tie-bet-area {
    color: #0f0;
}

.bet-area-label-top, .bet-area-label-bot, .bet-area-val {
    display: block;
}

.bet-area-label-top {
    font-size: 1.2vw;
    font-weight: 900;
}

.bet-area-label-bot {
    font-size: 1vw;
    bottom: 0;
    right: 0;
    left: 0;
    position: absolute;
    color: white;
    font-size: 15px;
    padding-bottom: 5px;
}

.tie-bot-label {
    margin-bottom: -30px;
}

.tie-bot-val {
    padding-top: 10px;
}

.bet-area-val {
    font-size: 1.5vw;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    color: gold;
}

.baccarat{
    background-image: url('/assets/images/gameroom/baccarat-d.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.betting-action-area {
    text-align: center;
    /* bottom: 2vh; */
    width: 99%;
    position: relative;
}
.table-lobby{
    position: absolute;
    height: 150px;
    width: 54px;
    background-color: #130f40;
    background: rgba(0, 0, 0, 0.5);
    right:0px;
    z-index: 1;
    top:37%;
    border: 1px solid rgba(190, 174, 147, 0.6);
}
.betting-details {
    position: relative;
    margin-right: 2px;
}

.bar_row3 {
    margin-top: 0px;
    background: rgba(0, 0, 0, 0.3);
    padding: 6px 10px;
    border-radius: 3px;
    position: relative;
    margin-left:0px;
    margin-right:0px;
    height:4vh;
}

.reloadb {
    height:3vh;
}

.baccarat-dt{
    background-image: url('/assets/images/gameroom/dragon-tiger-d.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.bet-area-bottom-dt {
    height: 55%;
    padding-top: 3vh;
}
</style>
