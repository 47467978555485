<!--=========================================================================================
  File Name: ShoeArea.vue
  Description: Shoe Area Component
==========================================================================================-->
<template>
<div class="row shoe-area" style="background-color: white;margin-top: -8px;" ref='shoe-area-ref'>
    <div style="height:20vh;overflow:hidden;border-right: 1px solid #0000003d"  class="col-5 rm-padding grid-x-repeat-m" v-bind:style="{backgroundSize: resultRoadH + ' 100% '}">
        <result-road :shoeResults="currentRoom.shoe" :heightShoe="resultRoadH" :widthShoe="resultRoadW" :gameType = 'gameType'/>
        <div class="bead_road_text" style="
             font-size: 2vh;
             font-weight: bold;
             position: absolute;
             color: #0000003d;
             bottom:0px;
             right:10px;
         ">BEAD ROAD</div>
    </div>
    <div class="col-7 rm-padding">
        <div class='row'>
            <div class="col-12 rm-padding grid-x-repeat-m" v-bind:style="{backgroundSize: resultBRoadH + 'vh 100% '}" style="overflow:hidden;height:10vh;border-bottom: 1px solid #0000003d" >
                <div class="big_road_text" style="
                    font-size: 2vh;
                    font-weight: bold;
                    position: absolute;
                    color: #0000003d;
                    bottom: 0;
                    right: 13%;
                ">BIG ROAD</div>
                <result-big-road :shoeResults="currentRoom.shoe" :heightShoe="resultBRoadH" :widthShoe="resultBRoadW" />
            </div>
        </div>
        <div class='row'>
            <div class="col-12 rm-padding grid-x-repeat-m" v-bind:style="{backgroundSize: resultBRoadH + 'vh 100% '}" style="overflow:hidden;height:5vh;border-bottom: 1px solid #0000003d;padding-top: 1px;">
                <div class="big_eye_text" style="
                    font-size: 1.75vh;
                    font-weight: bold;
                    position: absolute;
                    color: #0000003d;
                    bottom: 0;
                    right: 13%;
                ">BIG EYE ROAD</div>
                <result-big-eye-road :shoeResults="currentRoom.shoe" />
            </div>
        </div>
        <div class='row'>
            <div class="col-6 rm-padding grid-x-repeat-m" v-bind:style="{backgroundSize: resultBRoadH + 'vh 100% '}" style="overflow:hidden;height:5vh;border-right: 1px solid #0000003d;padding-top: 1px;">
                <div class="small_road_text" style="
                    font-size: 1.5vh;
                    font-weight: bold;
                    position: absolute;
                    color: #0000003d;
                    top: 2.5vh;
                    right: 13%;
                ">SMALL ROAD</div>
                <result-small-road :shoeResults="currentRoom.shoe" />
            </div>
            <div class="col-6 rm-padding grid-x-repeat-m" v-bind:style="{backgroundSize: resultBRoadH + 'vh 100% '}" style="overflow:hidden;height:5vh;padding-top: 1px;">
                <div class="cockroach_text" style="
                    font-size: 1.5vh;
                    font-weight: bold;
                    position: absolute;
                    color: #0000003d;
                    top: 1.5vh;
                    right: 13%;
                    ">COCKROACH ROAD</div>
                <result-cockroach-road :shoeResults="currentRoom.shoe" />
            </div>
        </div>
    </div>

</div>
</template>

<script>
import ResultRoad from '@/components/baccarat/roads/mobile/ResultRoad'
import ResultBigRoad from '@/components/baccarat/roads/mobile/ResultBigRoad'
import ResultBigEyeRoad from '@/components/baccarat/roads/gameroom/mobile/ResultBigEyeRoad'
import ResultSmallRoad from '@/components/baccarat/roads/gameroom//mobile/ResultSmallRoad'
import ResultCockroachRoad from '@/components/baccarat/roads/gameroom/mobile/ResultCockroachRoad'

export default {
    name: 'ShoeArea',
    components: {
        ResultRoad,
        ResultBigRoad,
        ResultBigEyeRoad,
        ResultSmallRoad,
        ResultCockroachRoad
    },
    data() {
        return {
            ppredictBigEye: "",
            ppredictSmall: "",
            ppredictCockRoach: "",
            bpredictBigEye: "",
            bpredictSmall: "",
            bpredictCockRoach: "",
            road: [],
            bigRoad: [],
            bigEyeRoad: [],
            smallRoad: [],
            cockroachRoad: [],
            resultRoadH: "3.30vh",
            resultRoadW: "3.30vh",
            resultBRoadH: 1.6,
            resultBRoadW: 1.6,
        }
    },
    props: {
        gameType: {required: true, type: String},
    },
    methods: {
        predictShoe() {},
    },
    mounted: function () {
        //e = blue - player
        //a = red - banker
        this.ppredictBigEye = this.predictShoe3 == "b" ? "e" : "a",
            this.ppredictSmall = this.predictShoe4 == "b" ? "e" : "a",
            this.ppredictCockRoach = this.predictShoe5 == "b" ? "e" : "a",
            this.bpredictBigEye = this.predictShoe3 == "r" ? "e" : "a",
            this.bpredictSmall = this.predictShoe4 == "r" ? "e" : "a",
            this.bpredictCockRoach = this.predictShoe5 == "r" ? "e" : "a"
    },
    computed: {
        currentRoom() {
            return this.$store.state.gameModule.currentRoom
        },
        totalShoe() {
            return this.currentRoom.shoe.length || 0
        },
        bankerShoe() {
            return this.currentRoom.shoe.split('').filter(s => s == 'a').length || 0
        },
        playerShoe() {
            return this.currentRoom.shoe.split('').filter(s => s == 'e').length || 0
        },
        tieShoe() {
            return this.currentRoom.shoe.split('').filter(s => s == 'i').length || 0
        },
        bankerPairShoe() {
            return this.currentRoom.shoe.split('').filter(s => s == 'c' || s == 'd' || s == 'g' || s == 'h').length || 0
        },
        playerPairShoe() {
            return this.currentRoom.shoe.split('').filter(s => s == 'b' || s == 'd' || s == 'f' || s == 'h').length || 0
        },
        predictShoe3: function () {
            var str = this.$store.state.gameModule.currentRoom.shoe;
            var bigeyecnt = this.$store.state.gameModule.bigeyeroadcount;
            var finalPanel;

            var tmpStr = "";
            for (var i = 0, len = str.length; i <= len; i++) {

                if (str.charAt(i) != "i" && str.charAt(i) != "j" && str.charAt(i) != "k" && str.charAt(i) != "l") {

                    if (str.charAt(i) == "a" || str.charAt(i) == "b" || str.charAt(i) == "c" || str.charAt(i) == "d")
                        tmpStr = tmpStr + "r";

                    if (str.charAt(i) == "e" || str.charAt(i) == "f" || str.charAt(i) == "g" || str.charAt(i) == "h")
                        tmpStr = tmpStr + "b";
                }
            }

            var tmpStr1 = "";
            for (var x = 0, len1 = tmpStr.length; x <= len1; x++) {
                var prevChar = "";
                if (x == 0)
                    prevChar = "";
                else
                    prevChar = tmpStr.charAt(i - 1);

                var curChar = "";
                if (prevChar != tmpStr.charAt(x)) {
                    curChar = "||" + tmpStr.charAt(x);
                } else {
                    curChar = tmpStr.charAt(x);
                }
                tmpStr1 = tmpStr1 + curChar;
            }

            var tmpArr = tmpStr1.split("||");
            //var pnl3Str = "";

            for (var key in tmpArr) {
                if (key == 2) {
                    if (tmpArr[key].length > 1) {
                        var tmpBR = false;
                        for (var z = 0, len2 = tmpArr[key].length - 1; z <= len2; z++) {
                            if (z > 0) {
                                if ((tmpArr[key - 1].length) > z) {
                                    finalPanel = 'r';
                                } else {
                                    if (!tmpBR) {

                                        finalPanel = 'b';
                                        tmpBR = true;
                                    } else {
                                        finalPanel = 'r';
                                    }
                                }
                            }
                        }
                    }
                }

                if (key > 2) {
                    for (var y = 0, len3 = tmpArr[key].length - 1; y <= len3; y++) {
                        if (y == 0) {
                            if (key >= 3) {
                                if (tmpArr[key - 2].length == tmpArr[key - 1].length) {
                                    finalPanel = 'r';
                                } else {
                                    finalPanel = 'b';
                                    tmpBR = false;
                                }
                            } else {
                                if (bigeyecnt == 0) {
                                    finalPanel = 'r';
                                    tmpBR = false;
                                } else {
                                    finalPanel = 'b';
                                    tmpBR = false;
                                }
                            }
                        }
                        if (y > 0) {
                            if (tmpArr[key - 1].length == y)
                                tmpBR = false;

                            if ((tmpArr[key - 1].length) > y) {
                                if (tmpBR) {
                                    finalPanel = 'b';
                                    tmpBR = false;
                                } else {
                                    finalPanel = 'r';
                                }
                            } else {
                                if (!tmpBR) {
                                    finalPanel = 'b';
                                    tmpBR = true;
                                } else {
                                    finalPanel = 'r';
                                }
                            }
                        }
                    }
                }
            }

            return finalPanel;
        },
        predictShoe4: function () {
            var str = this.$store.state.gameModule.currentRoom.shoe;
            var smallroadcnt = this.$store.state.gameModule.smallroadcount;
            var finalPanel;

            var tmpStr = "";
            for (var i = 0, len = str.length; i <= len; i++) {
                if (str.charAt(i) != "i" && str.charAt(i) != "j" && str.charAt(i) != "k" && str.charAt(i) != "l") {
                    if (str.charAt(i) == "a" || str.charAt(i) == "b" || str.charAt(i) == "c" || str.charAt(i) == "d")
                        tmpStr = tmpStr + "r";
                    if (str.charAt(i) == "e" || str.charAt(i) == "f" || str.charAt(i) == "g" || str.charAt(i) == "h")
                        tmpStr = tmpStr + "b";
                }
            }

            var tmpStr1 = "";
            for (var x = 0, len2 = tmpStr.length; x <= len2; x++) {
                var prevChar = "";
                if (x == 0)
                    prevChar = "";
                else
                    prevChar = tmpStr.charAt(x - 1);

                var curChar = "";
                if (prevChar != tmpStr.charAt(x)) {
                    curChar = "||" + tmpStr.charAt(x);
                } else {
                    curChar = tmpStr.charAt(x);
                }
                tmpStr1 = tmpStr1 + curChar;
            }

            var tmpArr = tmpStr1.split("||");
            for (var key in tmpArr) {
                if (key == 3) {
                    if (tmpArr[key].length > 0) {
                        var tmpBR = false;
                        for (var y = 0, len3 = tmpArr[key].length - 1; y <= len3; y++) {
                            if (y > 0) {
                                if ((tmpArr[key - 2].length) > y) {
                                    finalPanel = 'r';
                                } else {
                                    if (!tmpBR) {
                                        finalPanel = 'b';
                                        tmpBR = true;
                                    } else {
                                        finalPanel = 'r';
                                    }
                                }
                            }
                        }
                    }
                }

                if (key > 3) {
                    for (var z = 0, len4 = tmpArr[key].length - 1; z <= len4; z++) {
                        if (z == 0) {
                            if (key > 3) {
                                if (tmpArr[key - 3].length == tmpArr[key - 1].length) {
                                    finalPanel = 'r';
                                } else {
                                    finalPanel = 'b';
                                    tmpBR = false;
                                }
                            } else {
                                if (smallroadcnt == 0) {
                                    finalPanel = 'r';
                                    tmpBR = false;
                                } else {
                                    finalPanel = 'b';
                                    tmpBR = false;
                                }
                            }
                        }
                        if (z > 0) {
                            if (tmpArr[key - 2].length == z)
                                tmpBR = false;
                            if ((tmpArr[key - 2].length) > z) {
                                if (tmpBR) {
                                    finalPanel = 'b';
                                    tmpBR = false;
                                } else {
                                    finalPanel = 'r';
                                }
                            } else {
                                if (!tmpBR) {
                                    finalPanel = 'b';
                                    tmpBR = true;
                                } else {
                                    finalPanel = 'r';
                                }
                            }
                        }
                    }
                }
            }

            return finalPanel;
        },
        predictShoe5: function () {
            var str = this.$store.state.gameModule.currentRoom.shoe;
            var smallroadcnt = this.$store.state.gameModule.croadcount;

            var finalPanel;
            var tmpStr = "";
            for (var i = 0, len = str.length; i <= len; i++) {
                if (str.charAt(i) != "i" && str.charAt(i) != "j" && str.charAt(i) != "k" && str.charAt(i) != "l") {
                    if (str.charAt(i) == "a" || str.charAt(i) == "b" || str.charAt(i) == "c" || str.charAt(i) == "d")
                        tmpStr = tmpStr + "r";
                    if (str.charAt(i) == "e" || str.charAt(i) == "f" || str.charAt(i) == "g" || str.charAt(i) == "h")
                        tmpStr = tmpStr + "b";
                }
            }

            var tmpStr1 = "";

            for (var x = 0, len1 = tmpStr.length; x <= len1; x++) {
                var prevChar = "";
                if (x == 0)
                    prevChar = "";
                else
                    prevChar = tmpStr.charAt(x - 1);

                var curChar = "";
                if (prevChar != tmpStr.charAt(x)) {
                    curChar = "||" + tmpStr.charAt(x);
                } else {
                    curChar = tmpStr.charAt(x);
                }
                tmpStr1 = tmpStr1 + curChar;
            }

            var tmpArr = tmpStr1.split("||");
            var tmpBR;
            for (var key in tmpArr) {
                if (key == 4) {
                    if (tmpArr[key].length >= 1) {
                        tmpBR = false;
                        for (var y = 0, len2 = tmpArr[key].length - 1; y <= len2; y++) {
                            if (y > 0) {
                                if ((tmpArr[key - 3].length) > y) {
                                    finalPanel = 'r';
                                } else {
                                    if (!tmpBR) {
                                        finalPanel = 'b';
                                        tmpBR = true;
                                    } else {
                                        finalPanel = 'r';
                                    }
                                }
                            }
                        }
                    }
                }

                if (key > 4) {
                    for (var z = 0, len3 = tmpArr[key].length - 1; z <= len3; z++) {
                        if (z == 0) {
                            if (key > 4) {
                                if (tmpArr[key - 4].length == tmpArr[key - 1].length) {
                                    finalPanel = 'r';
                                } else {
                                    finalPanel = 'b';
                                    tmpBR = false;
                                }
                            } else {
                                if (smallroadcnt == 0) {
                                    finalPanel = 'r';
                                    tmpBR = false;
                                } else {
                                    finalPanel = 'b';
                                    tmpBR = false;
                                }
                            }
                        }
                        if (z > 0) {
                            if (tmpArr[key - 3].length == z)
                                tmpBR = false;

                            if ((tmpArr[key - 3].length) > z) {
                                if (tmpBR) {
                                    finalPanel = 'b';
                                    tmpBR = false;
                                } else {
                                    finalPanel = 'r';
                                }
                            } else {
                                if (!tmpBR) {
                                    finalPanel = 'b';
                                    tmpBR = true;
                                } else {
                                    finalPanel = 'r';
                                }
                            }
                        }
                    }
                }
            }

            return finalPanel;
        }
    },
    watch: {
        //e = blue - player
        //a = red - banker
        predictShoe3: {
            handler() {
                this.ppredictBigEye = this.predictShoe3 == "b" ? "e" : "a"
                this.bpredictBigEye = this.predictShoe3 == "r" ? "e" : "a";
            },
            deep: true
        },
        predictShoe4: {
            handler() {
                this.ppredictSmall = this.predictShoe4 == "b" ? "e" : "a";
                this.bpredictSmall = this.predictShoe4 == "r" ? "e" : "a";
            },
            deep: true
        },
        predictShoe5: {
            handler() {
                this.ppredictCockRoach = this.predictShoe5 == "b" ? "e" : "a";
                this.bpredictCockRoach = this.predictShoe5 == "r" ? "e" : "a";
            },
            deep: true
        },
    },
}
</script>

<style>
.btn-shoe {
    position: absolute;
    bottom: 0px;
    font-size: 1.5vh;
    color: #beae93;
}

.player-shoe-div {
    background-color: white;
    height: 35px;
    position: relative;
    margin: 0 auto;
    padding-top: 7px;
    border-radius: 5px;
    text-align: center;
    padding-left: 10px;
    margin-left: 10px;
    margin-right: 10px;
}

.predict-bead {
    max-width: 100% !important;
    flex: 1;
    padding-right: 0px;
    padding-left: 0px;
}

.sp-player .p-span,
.sp-banker .b-span {
    font-size: 1vw;
}

.rs-ctnr-gr {
    height: 100%;
}

.rm-padding {
    padding-left: 0px;
    padding-right: 0px;
}

.container-count {
    display: flex;
    /* add -webkit and -ms prefixes yourself */
    flex-direction: column;
    margin: 0;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.container-box {
    flex-grow: 1;
    margin: 1px;
}

.shoe-table {
    height: 20vh !important
}

@media only screen and (orientation: landscape) {
    .shoe-table {
        height: 22vh !important
    }
}
</style>
