<!--=========================================================================================
  File Name: ResultBigRoad.vue
  Description: Result Big Road Component
==========================================================================================-->
<template>
<div class="content" style="padding: 0px">
   <div class="content" style="padding: 0px">
     <div class="big-eye-road">
          <div class="rs-ctnr d-flex flex-row grid-y grid-y-repeat-big-m">
              <div class="flex-column" v-for="(shoeM,x) in shoeMatrixBigEye" :key="x">
                   <div v-for="(shoe, i) in shoeM" v-bind:key="shoe.index + '-' + i" class="big-road-shoe" style="height:0.8vh;width: 0.8vh;"
                       v-bind:class="[{ animate_latest: x == shoeMatrixBigEye.length - 1 && (shoe.shoe !='' && i == $getLasttIndex(shoeMatrixBigEye, x) )}, shoe.shoe + '-big-eye-road']">
                   </div>
              </div>
           </div>
      </div>
   </div>
</div>
</template>
<script>

export default {
    name: 'ResultBigEyeRoad',
    components: {},
    data() {
        return {
        }
    },
    props: {
        shoeResults: {required: true, type: String}
    },
    watch: {
        shoeResults: {
            handler() {
                this.$store.commit('gameModule/SET_BIGEYEROAD_COUNT', this.shoeResults.length);
            }
        }
    },
    computed: {
        shoeMatrix() {
            let matrix = [], prevShoe = '', columnIdx = -1, rowIdx = 1, sameShoeCnt = 0, tempColumnIdx = -1,  tmpInd = 5, tieCnt = 0
            const tieShoe = ['i', 'j', 'k', 'l']

            this.shoeResults.split('').forEach((shoe) => {
                if (!(tieShoe.indexOf(shoe) !== -1)) { // If shoe is not tie
                    if(!((this.$prevShoeArr(prevShoe)).indexOf(shoe) !== -1)) { // create new column
                        if(matrix[columnIdx + 1]) { // check if there's an over shoe, overwrite 1st column
                            matrix[columnIdx + 1][0] = { shoe, count:  0}
                        } else {
                            matrix.push([{ shoe, count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }])
                        }
                        rowIdx = 1; // reset row index to 1 (next vertical shoe)
                        sameShoeCnt = 0 // reset same shoe count
                        columnIdx++; // increment column index
                        tempColumnIdx = columnIdx;
                        tieCnt = 0;
                    } else { // normal add
                        if (sameShoeCnt > rowIdx) {
                            matrix.push([{  shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }])
                            tempColumnIdx++
                            matrix[tempColumnIdx][rowIdx] = { shoe, count: 0 }
                            if (tmpInd < 5) { //
                                if(matrix[tempColumnIdx][rowIdx+1].shoe == '') {
                                    tempColumnIdx--
                                    rowIdx++
                                    tmpInd = rowIdx
                                }
                            }
                        } else {
                            if(matrix[tempColumnIdx] && matrix[tempColumnIdx][rowIdx].shoe == '') {
                                matrix[tempColumnIdx][rowIdx] = { shoe, count: 0 }
                            } else {
                                tmpInd = rowIdx - 1
                                // check if the matrix has enough length to handle dragon tail
                                if(matrix.length < tempColumnIdx)
                                {
                                    matrix.push([{  shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }])
                                }

                                matrix.push([{  shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }])
                                tempColumnIdx++
                                rowIdx--
                                matrix[tempColumnIdx][rowIdx] = { shoe, count: 0 }

                                // Always check the bottom index
                                if (tmpInd < 5) {
                                    if(matrix[tempColumnIdx][rowIdx+1].shoe == '') {
                                        tempColumnIdx--
                                        rowIdx++
                                        tmpInd = rowIdx
                                    }
                                }
                            }
                        }
                    if(rowIdx < tmpInd)
                        rowIdx++; // increment row index
                    }
                    prevShoe = shoe // set current shoe as prev
                    sameShoeCnt++; // same Shoe
                    tieCnt = 0 // reset tie counting.
                } else {
                    tieCnt++;
                    if (rowIdx > -1 && tempColumnIdx > -1) {
                        matrix[tempColumnIdx][rowIdx-1] = { shoe:matrix[tempColumnIdx][rowIdx-1].shoe, count: tieCnt }
                    }
                }
             })
             return matrix
        },
        shoeMatrixBigEye() {
            let bigRoad = this.shoeMatrix
            let bigEyedArr = []

            // Bigroad array
            bigRoad.forEach((shoeArr, k) => {
                if (k > 1) {
                    shoeArr.forEach((item, i) => {
                        if(item.shoe != '' ) {
                            var res = this.$shoePredict(bigRoad, k, i);
                            bigEyedArr.push(res);
                        }
                    });
                }
            });

            // Initialized the bigeyeroad data
            let matrix = [], prevShoe = '', columnIdx = -1, rowIdx = 1, sameShoeCnt = 0, tempColumnIdx = -1,  tmpInd = 5

            bigEyedArr.forEach((shoe) => {

                    if(!((this.$prevShoeArr(prevShoe)).indexOf(shoe) !== -1)) { // create new column
                        if(matrix[columnIdx + 1]) { // check if there's an over shoe, overwrite 1st column
                            matrix[columnIdx + 1][0] = { shoe, count:  0}
                        } else {
                            matrix.push([{ shoe, count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }])
                        }
                        rowIdx = 1; // reset row index to 1 (next vertical shoe)
                        sameShoeCnt = 0 // reset same shoe count
                        columnIdx++; // increment column index
                        tempColumnIdx = columnIdx;
                    } else { // normal add
                        if (sameShoeCnt > rowIdx) {
                            matrix.push([{  shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }])
                            tempColumnIdx++
                            matrix[tempColumnIdx][rowIdx] = { shoe, count: 0 }
                            if (tmpInd < 5) { //
                                if(matrix[tempColumnIdx][rowIdx+1].shoe == '') {
                                    tempColumnIdx--
                                    rowIdx++
                                    tmpInd = rowIdx
                                }
                            }
                        } else {
                            if(matrix[tempColumnIdx] && matrix[tempColumnIdx][rowIdx].shoe == '') {
                                matrix[tempColumnIdx][rowIdx] = { shoe, count: 0 }
                            } else {
                                tmpInd = rowIdx - 1
                                // check if the matrix has enough length to handle dragon tail
                                if(matrix.length < tempColumnIdx)
                                {
                                    matrix.push([{  shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }])
                                }

                                matrix.push([{  shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }])
                                tempColumnIdx++
                                rowIdx--
                                matrix[tempColumnIdx][rowIdx] = { shoe, count: 0 }

                                // Always check the bottom index
                                if (tmpInd < 5) {
                                    if(matrix[tempColumnIdx][rowIdx+1].shoe == '') {
                                        tempColumnIdx--
                                        rowIdx++
                                        tmpInd = rowIdx
                                    }
                                }
                            }
                        }
                    if(rowIdx < tmpInd)
                        rowIdx++; // increment row index
                    }
                    prevShoe = shoe // set current shoe as prev
                    sameShoeCnt++; // same Shoe
             })
             return matrix
        }
    }
}
</script>
